import './_vendor';

$("#mainForm").on("submit", function (event) {
  event.preventDefault(); // Отменяем стандартное поведение формы

  const data = {
    name: $("#name").val(),
    phone: $("#phone").val(),
    dateStart: $("#dateStart").val(),
    dateEnd: $("#dateEnd").val(),
    guests: $("#guest").val(),
  };

  $.ajax({
    url: "mail.php", // Здесь указываем URL-адрес серверного обработчика
    type: "post",
    data: data,
    success: function (response) {
      // Обработка успешной отправки данных
      $("#exampleModal").modal("hide");

      console.log("Данные успешно отправлены!");
      $("#modal").fadeIn(500);
      // console.log(response);
    },
    error: function (error) {
      // Обработка ошибок при отправке данных
      console.error("Ошибка при отправке данных: ", error);
    },
  });
});


//если нажата кнопка закрытия окна
$('.modal-window .close').click(function (e) {
  //Отменяем поведение ссылки
  e.preventDefault();
  $('.modal-window').fadeOut(500);
});


// Меню
$('.burger-menu').click(function (event) {
  $('.burger-menu, .header__nav').toggleClass('active');
  $('body').toggleClass('lock');
});

// кнопка прокрутки вверх btn-up
const btnUP = {
  el: document.querySelector('.btn-up'),
  show() {
    this.el.classList.remove('btn-up__hide');
  },
  hide() {
    this.el.classList.add('btn-up__hide');
  },

  addEventListener() {
    window.addEventListener('scroll', () => {
      const csrollY = window.scrollY || document.documentElement.scrollTop;

      csrollY > 400 ? this.show() : this.hide();
    });

    document.querySelector('.btn-up').onclick = () => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    }
  }
}

btnUP.addEventListener();

// Предупреждение о использовании cookie
function setCookie(name, value, days) {
  let expires = "";
  if (days) {
    let date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

function getCookie(name) {
  let matches = document.cookie.match(new RegExp("(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"));
  return matches ? decodeURIComponent(matches[1]) : undefined;
}

function checkCookies() {
  let cookieNote = document.querySelector('.cookie-note');
  let cookieBtnAccept = cookieNote.querySelector('.cookie-accept');

  // Если куки cookies_policy нет или она просрочена, то показываем уведомление
  if (!getCookie('cookies_policy')) {
    cookieNote.classList.add('cookie-show');
  }

  // При клике на кнопку устанавливаем куку cookies_policy на один год
  cookieBtnAccept.addEventListener('click', function () {

    setCookie('cookies_policy', 'true', 365);
    cookieNote.classList.remove('cookie-show');
  });
}

checkCookies();

// Hero slider
const swiper = new Swiper('.swiper', {
  speed: 400,
  spaceBetween: 100,
  // Optional parameters
  direction: 'horizontal',
  loop: true,

  // If we need pagination
  pagination: {
    el: '.swiper-pagination',
  },

  // Navigation arrows
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },

  // And if we need scrollbar
  scrollbar: {
    el: '.swiper-scrollbar',
  },
});

